const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  colorBlack : '#252525',
  colorBlue : '#53acb3',
  colorGreenGrey : '#c5d7d7',
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"DIN Next LT Pro",serif',

  primaryColor: '#c20f2f',
  fontColor: colors.colorBlack,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;